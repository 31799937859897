import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Instagram from '../../pages/icons/Instagram.svg';
import Facebook from '../../pages/icons/Facebook.svg';
import { RECIPES_PATH, EVENTS_PATH, COACHING_PATH, WHO_AM_I_PATH } from '../../constants/Routes';
import { FACEBOOK_URL, INSTAGRAM_URL } from '../../constants/External';

import { Container, Anchor, Logo, Copyright, SocialContainer, Ul } from './style';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date]},
          filter: { fields: { collection: { eq: "recipes" } } },
          limit: 4
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Container>
        <Grid>
          <Row>
            <Col md={4}>
              <h4>Na Caverna da Marta</h4>

              <Ul>
                <li>
                  <Anchor to="/">
                    Home
                  </Anchor>
                </li>

                <li>
                  <Anchor to={RECIPES_PATH}>
                    Receitas
                  </Anchor>
                </li>

                <li>
                  <Anchor to={EVENTS_PATH}>
                    Eventos
                  </Anchor>
                </li>

                <li>
                  <Anchor to={COACHING_PATH}>
                    Coaching
                  </Anchor>
                </li>

                <li>
                  <Anchor to={WHO_AM_I_PATH}>
                    Quem Sou
                  </Anchor>
                </li>
              </Ul>
            </Col>

            <Col md={5}>
              <h4>Receitas</h4>

              <Ul>
                {data && data.allMarkdownRemark.edges.map(({ node }, i) => (
                  <li key={i}>
                    <Anchor to={`${RECIPES_PATH}/${node.fields.slug}`}>
                      {node.frontmatter.title}
                    </Anchor>
                  </li>
                ))}

                <li>
                  <Anchor to={RECIPES_PATH}>
                    Ver todas as receitas
                  </Anchor>
                </li>
              </Ul>
            </Col>

            <Col md={3}>
              <Logo>
                Na Caverna <br /> da Marta
              </Logo>

              <Copyright>
                &copy; {new Date().getFullYear()}
              </Copyright>

              <SocialContainer>
                <h4>Siga-nos:</h4>

                <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
                  <Facebook />
                </a>

                <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
                  <Instagram />
                </a>
              </SocialContainer>
            </Col>
          </Row>
        </Grid>
      </Container>
    )}
  />
);

export default Footer;
