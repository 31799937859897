import styled from 'styled-components';
import { Link } from 'gatsby';

export const Container = styled.div`
  border-top: 1px #eee solid;
  margin-top: 80px;
  padding: 80px 0;
`;

export const Anchor = styled(Link)`
  font-size: 15px;
  color: #faab06;
`;

export const Logo = styled.div`
  font-family: 'Playfair Display';
  font-size: 36px;
  line-height: 1;
  font-weight: 900;
  margin-top: 30px;
  color: #666;
`;

export const Copyright = styled.div`
  font-size: 14px;
  color: #9baec8;
  margin-top: 15px;
`;

export const SocialContainer = styled.div`
  margin-top: 15px;

  h4 {
    margin-bottom: 15px;
  }

  svg {
    width: 30px;
    fill: #999;
  }

  a {
    margin-right: 20px;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  margin: 0 0 30px;
`;
